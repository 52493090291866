import React, { FC, useMemo } from 'react';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';
import config from '~/config';
import { PageType, TBreadcrumbs } from '~/utils/jsonLd/types';
import { getJsonLdBody } from '~/utils/jsonLd/service';
import { Post } from '~/resources/models';

interface TProps {
  name?: string
  breadcrumbs?: TBreadcrumbs
  pageType?: PageType
  posts?: Post[]
}

const JsonLd: FC<TProps> = ({
  name = '', breadcrumbs, pageType = PageType.NONE, posts = [],
}) => {
  const location = useLocation();
  const url = `${config('APP_HOST')}${location.pathname}`;

  const generateData = useMemo<JSX.Element>(() => {
    const body = getJsonLdBody(url, name, breadcrumbs, pageType, posts);
    return (
      <script type="application/ld+json">
        {JSON.stringify(body)}
      </script>
    );
  }, [name, location]);

  return (
    <Helmet>
      <meta name="key" content="Reminder" />
      {generateData}
    </Helmet>
  );
};

JsonLd.displayName = 'JsonLd';
export default JsonLd;
