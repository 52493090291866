import React, { FC, useMemo } from 'react';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';
import config from '~/config';
import { Post } from '~/resources/models';
import { getPostJsonLdBody } from '~/utils/jsonLd/service';
import { TBreadcrumbs } from '~/utils/jsonLd/types';

interface TProps {
  post: Post;
  breadcrumbs?: TBreadcrumbs;
}

const JsonLdPost: FC<TProps> = ({ post, breadcrumbs }) => {
  const location = useLocation();
  const url = `${config('APP_HOST')}${location.pathname}`;

  const generateData = useMemo<JSX.Element>(() => {
    const body = getPostJsonLdBody(url, post, breadcrumbs);
    return (
      <script type="application/ld+json">
        {JSON.stringify(body)}
      </script>
    );
  }, [post, location]);

  return (
    <Helmet>
      {generateData}
    </Helmet>
  );
};

JsonLdPost.displayName = 'JsonLdPost';
export default JsonLdPost;
