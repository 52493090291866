import React, { FC, useEffect } from 'react';

import {
  Page, Paginator, ReportsList,
} from '~/components';
import { useConfig } from '~/resources';
import config from '~/config';
import Banner from '~/components/Banner';
import { useLocation } from 'react-router';
import { TBreadcrumbs } from '~/utils/jsonLd/types';
import { BREADCRUMB_MAIN } from '~/utils/jsonLd/breadcrumbs';
import Helmeter from './Helmeter';
import JsonLd from './jsonLd';

const ReportsScreen: FC = () => {
  const location = useLocation();
  const { fetchBy } = useConfig();
  const canonicalLink = `${config('APP_HOST')}/reports`;
  useEffect(() => {
    fetchBy();
  }, []);
  const breadcrumbs: TBreadcrumbs = [
    BREADCRUMB_MAIN,
    {
      name: 'Отчеты',
      href: location.pathname,
    },
  ];

  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          title="Отчеты — Reminder"
          description="Отчеты Reminder — это сборники материалов, в которых глубоко исследуется тема и даются практические советы."
          metaDescription="Отчеты Reminder — это сборники материалов, в которых глубоко исследуется тема и даются практические советы."
          img={`${config('APP_HOST')}/public/reports_og.png`}
          link={[
            { rel: 'canonical', href: canonicalLink },
          ]}
        />
        <JsonLd name="Отчеты" breadcrumbs={breadcrumbs} />
        <Paginator>
          {(page, handleMeta, handleLoading) => (
            <ReportsList
              page={page}
              onMeta={handleMeta}
              onLoading={handleLoading}
            />
          )}
        </Paginator>
      </Page>
    </>
  );
};

ReportsScreen.displayName = 'ReportsScreen';
export default ReportsScreen;
