import React, { FC, useEffect } from 'react';
import { Page, Paginator } from '~/components';
import PostList from '~/components/post/PostList';
import { useConfig } from '~/resources';
import config from '~/config';
import JsonLd from '~/screens/jsonLd';
import Banner from '~/components/Banner';
import { PostType } from '~/resources/PostType';
import { PageType, TBreadcrumbs } from '~/utils/jsonLd/types';
import { BREADCRUMB_MAIN } from '~/utils/jsonLd/breadcrumbs';
import usePosts from '~/resources/usePosts';
import Helmeter from './Helmeter';

const canonicalLink = config('APP_HOST');

const HomeScreen: FC = () => {
  const { fetchBy } = useConfig();
  const paramsInit = {
    type: [PostType.ARTICLE, PostType.GUIDE, PostType.SPECIAL],
    page: String(1),
  };
  const { data: posts } = usePosts(paramsInit, {}, 'main_page');
  const breadcrumbs: TBreadcrumbs = [
    BREADCRUMB_MAIN,
  ];

  useEffect(() => {
    fetchBy();
  }, []);

  return (
    <>
      <Banner />
      <Page isMainPage>
        <Helmeter
          link={[
            { rel: 'canonical', href: canonicalLink },
          ]}
        />
        <JsonLd breadcrumbs={breadcrumbs} pageType={PageType.MAIN} posts={posts} />
        <Paginator>
          {(page, handleMeta, handleLoading) => (
            <PostList
              isMainPage
              page={page}
              onMeta={handleMeta}
              onLoading={handleLoading}
              params={{
                type: [PostType.ARTICLE, PostType.GUIDE, PostType.SPECIAL],
                page: String(page),
              }}
              noDividersIdx={() => [1, 2]}
            />
          )}
        </Paginator>
      </Page>
    </>
  );
};

HomeScreen.displayName = 'HomeScreen';
export default HomeScreen;
