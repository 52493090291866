import config from '~/config';
import { Post } from '~/resources/models';
import { PostType } from '~/resources/PostType';

const host = config('APP_HOST');

const getPrefix = (post: Post | undefined): string => {
  if (post?.type === PostType.SPECIAL) {
    return PostType.SPECIAL;
  }
  // post?.preview_mode === 'preview_super' ? PostType.SUPER : PostType.LONGREAD;
  if (post?.preview_mode === 'preview_super') { return PostType.SUPER; }
  return 'post';
};

const getPostCanonicalUrl = (post: Post | undefined, slug: string): string => `${host}/${getPrefix(post)}/${slug}`;

export default getPostCanonicalUrl;
