import { TItemListElement } from './types';

export const itemListElement: TItemListElement[] = [
  {
    '@type': 'SiteNavigationElement',
    position: '01',
    name: 'О проекте',
    description: 'Reminder — это медиа о здоровье, саморазвитии и практической философии.',
    url: 'https://reminder.media/about',
  },
  {
    '@type': 'SiteNavigationElement',
    position: '02',
    name: 'Сознание',
    description: 'Топик статей — Сознание',
    url: 'https://reminder.media/topic/mind',
  },
  {
    '@type': 'SiteNavigationElement',
    position: '03',
    name: 'Работа',
    description: 'Топик статей — Работа',
    url: 'https://reminder.media/topic/work',
  },
  {
    '@type': 'SiteNavigationElement',
    position: '04',
    name: 'ЗОЖ',
    description: 'Топик статей — ЗОЖ',
    url: 'https://reminder.media/topic/health',
  },
  {
    '@type': 'SiteNavigationElement',
    position: '05',
    name: '',
    description: 'Топик статей — Отношения',
    url: 'https://reminder.media/topic/relationship',
  },
  {
    '@type': 'SiteNavigationElement',
    position: '06',
    name: 'Фан',
    description: 'Топик статей — Фан',
    url: 'https://reminder.media/topic/fun',
  },
  {
    '@type': 'SiteNavigationElement',
    position: '07',
    name: 'Картина мира',
    description: 'Топик статей — Картина мира',
    url: 'https://reminder.media/topic/world',
  },
  {
    '@type': 'SiteNavigationElement',
    position: '08',
    name: 'Гайды',
    description: 'Подробные разборы самых важных тем для подписчиков Reminder.',
    url: 'https://reminder.media/guides',
  },
  {
    '@type': 'SiteNavigationElement',
    position: '09',
    name: 'Реклама',
    description: 'Спецпроекты в Reminder',
    url: 'https://reminder.media/ads',
  },
];
