import React, { FC } from 'react';

const RubleIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18.746 21.454545454545457"
    width="100%"
    height="100%"
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      d="M10.7168 13.23H7.3268V14.73H13.8068V17.76H7.3268V20.91H2.9768V17.76H0.6368V14.73H2.9768V13.23H0.6368V9.69H2.9768V0.51H10.7468C13.0268 0.51 14.8068 1.02 16.0868 2.04C17.3668 3.04 18.0068 4.64 18.0068 6.84C18.0068 9 17.3568 10.61 16.0568 11.67C14.7768 12.71 12.9968 13.23 10.7168 13.23ZM7.3268 4.05V9.69H10.5668C11.7268 9.69 12.5468 9.44 13.0268 8.94C13.5268 8.42 13.7768 7.73 13.7768 6.87C13.7768 5.97 13.5268 5.28 13.0268 4.8C12.5268 4.3 11.7168 4.05 10.5968 4.05H7.3268Z"
    />
  </svg>
);

RubleIcon.displayName = 'RubleIcon';
export default RubleIcon;
