import React, { FC, useEffect } from 'react';
import c from '~/components/post/ContentPost.sass';
import ServiceText from '~/components/ServiceText';
import { Loader, Page, usePageContext } from '~/components';
import config from '~/config';
import Banner from '~/components/Banner';
import { FetchStatus, useConfig } from '~/resources';
import { useHistory, useLocation } from 'react-router';
import { TBreadcrumbs } from '~/utils/jsonLd/types';
import { BREADCRUMB_MAIN } from '~/utils/jsonLd/breadcrumbs';
import Helmeter from './Helmeter';
import JsonLd from './jsonLd';

const VideoScreen: FC = () => {
  const { auth, profile, profileStatus } = usePageContext();
  const location = useLocation();
  const history = useHistory();
  const { data: configData, fetchBy } = useConfig();

  useEffect(() => {
    auth?.clearCache();
  }, []);

  const status = profileStatus?.[0] ?? undefined;
  const isLoading = !status || status === FetchStatus.Fetching;
  const isProfile = profile && profile[0];
  const isAdmin = profile && profile[0] && profile[0].is_admin;
  const isSubscribed = profile && profile[0] && profile[0].is_subscribed;
  const isTextReady = configData
    && configData.service_texts
    && configData.service_texts.friends_page_text;

  useEffect(() => {
    if (!isLoading && (isSubscribed || isAdmin)) {
      fetchBy();
    }
  }, [isLoading, isProfile]);

  const canonicalLink = `${config('APP_HOST')}/video`;
  const breadcrumbs: TBreadcrumbs = [
    BREADCRUMB_MAIN,
    {
      name: 'Видео',
      href: location.pathname,
    },
  ];
  if (!isLoading && !isSubscribed && !isAdmin) {
    history.push('/404');
    return null;
  }

  return (
    <>
      <Banner />
      <JsonLd name="Видео" breadcrumbs={breadcrumbs} />
      <Page>
        <Helmeter
          title="Видео — Reminder"
          metaTitle="Видео — Reminder"
          metaDescription="Все видео встреч в сообществе Reminder"
          link={[
            { rel: 'canonical', href: canonicalLink },
          ]}
        />
        <div className={c.container}>
          <div className={c.wrap}>
            {(isLoading || !isTextReady) ? (
              <Loader />
            ) : (
              <ServiceText name="friends_page_text" />
            )}
          </div>
        </div>
      </Page>
    </>
  );
};

VideoScreen.displayName = 'VideoScreen';
export default VideoScreen;
