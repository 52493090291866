import React, { FC, useEffect } from 'react';

import { Loader, PreviewPost } from '~/components';
import Title from '~/components/typography/Title';

import useRecommends from '~/resources/useRecommends';
import { FetchStatus } from '~/resources';
import c from './Recommendations.sass';

type TProps = {
  postExcludeId?: number;
}

const Recommendations: FC<TProps> = ({ postExcludeId }) => {
  const { data: posts, fetchBy, status } = useRecommends({ post_id: String(postExcludeId) });

  const isLoading = status === FetchStatus.Fetching;
  const isError = status === FetchStatus.Error;

  useEffect(() => {
    fetchBy();
  }, [postExcludeId]);

  if (isError) return null;

  if (isLoading || !posts) return <Loader />;

  return (
    <div className={c.recommendation_section}>
      <Title level={1} className={c.recommendation_section__title} notHeader>
        Продолжайте читать
      </Title>
      <div className={c.guides_container}>
        {posts.map((post) => (
          <PreviewPost post={post} key={post.id} />
        ))}
      </div>
    </div>
  );
}

Recommendations.displayName = 'Recommendations';
export default Recommendations;
