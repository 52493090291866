import { SubscriptionPlan } from '~/resources/models';
import numberWithSpaces from '~/utils/price';

export const getPriceLabels = (plan: SubscriptionPlan) => {
  const { price, full_price, type } = plan;

  const fullPriceValidated = full_price ? full_price / 100 : 0;
  const isPromocodePlan = (plan.slug.includes('annual_') || plan.slug.includes('monthly_'))
    && full_price;
  const isPromotionPlan = plan.slug.includes('promotion_') && full_price;
  const crossedPrice = (isPromotionPlan || isPromocodePlan) ? full_price / 100 : undefined;
  const isAnnual = type === 'annual';

  const priceMonth = price / 100;
  const priceTitle = numberWithSpaces(priceMonth);

  const priceYear = Math.round(priceMonth * 12 * 100) / 100;

  const priceSubtitle = isAnnual
    ? 'Подарок: все книги Reminder'
    : numberWithSpaces(priceYear);

  const percent = Math.round(((fullPriceValidated - priceMonth) / fullPriceValidated) * 100);
  const percentLabel = (full_price && (isAnnual || isPromotionPlan || isPromocodePlan))
    ? `-${percent}%`
    : undefined;

  return {
    priceTitle, priceSubtitle, percentLabel, crossedPrice,
  };
};
