import React, { FC } from 'react';
import { useLocation, useParams } from 'react-router';
import config from '~/config';
import Banner from '~/components/Banner';
import { Page } from '~/components';
import { SubscriptionComponent } from '~/components/PaidSubscription';
import { PageType, TBreadcrumbs } from '~/utils/jsonLd/types';
import { BREADCRUMB_MAIN } from '~/utils/jsonLd/breadcrumbs';
import Helmeter from './Helmeter';
import JsonLd from './jsonLd';

const SubscriptionPublicScreen: FC = () => {
  const location = useLocation();
  const { discountPromocode } = useParams<{ discountPromocode: string | undefined }>();
  const ogImage = `${config('APP_HOST')}/public/subscription/subscription-og.png`;
  // const ampLink = `${config('APP_HOST')}/amp${pathname}`;
  const canonicalLink = `${config('APP_HOST')}/subscription`;
  const breadcrumbs: TBreadcrumbs = [
    BREADCRUMB_MAIN,
    {
      name: 'Подписка',
      href: location.pathname,
    },
  ];

  return (
    <>
      <Banner />
      <JsonLd name="подписка" breadcrumbs={breadcrumbs} pageType={PageType.SUBSCRIPTION} />
      <Page>
        <Helmeter
          title="Оптимизация жизни — Reminder"
          metaTitle="Оптимизация жизни — Reminder"
          metaDescription="Подписка на лучшее знание о здоровье и саморазвитии"
          link={[
            { rel: 'canonical', href: canonicalLink },
          ]}
          img={ogImage}
          srcScripts={['https://yookassa.ru/checkout-widget/v1/checkout-widget.js']}
        />
        <SubscriptionComponent discountPromocode={discountPromocode} />
      </Page>
    </>
  );
};

SubscriptionPublicScreen.displayName = 'SubscriptionPublicScreen';
export default SubscriptionPublicScreen;
