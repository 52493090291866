import React, { FC } from 'react';

import { Link } from 'react-router-dom';
import Paragraph from '~/components/typography/Paragraph';
import RssIcon from '~/components/icons/RssIcon';
import clsx from 'clsx';
import ItSummaLogoIcon from '~/components/icons/ItSummaLogoIcon';
import c from './FooterBottom.sass';

const FooterBottom: FC = () => {
  const yearLabel = `© Reminder ${new Date().getFullYear()}`;

  return (
    <div className={c.footer_bottom}>
      <Paragraph size="small" className={c.legal_info}>
        Все права защищены. Любое использование материалов допускается только
        при наличии гиперссылки на&nbsp;reminder.media. 18+
      </Paragraph>
      <Link to="/privacy_policy">
        <Paragraph size="small">
          Пользовательское соглашение
        </Paragraph>
      </Link>
      <Link to="/ads">
        <Paragraph size="small" className={c.legal_info}>
          Реклама
        </Paragraph>
      </Link>
      <div className={c.bottom_line}>
        <div className={c.bottom_line_left_column}>
          <div className={clsx(c.bottom_line_item, c.bottom_line_reminder)}><Paragraph variant="caption">{yearLabel}</Paragraph></div>
          <Link className={c.down_rss} target="_blank" to="/rss">
            <div className={clsx(c.bottom_line_item, c.bottom_line_rss)}>
              <RssIcon />
              <Paragraph variant="caption">RSS</Paragraph>
            </div>
          </Link>
        </div>
        <a className={c.it_summa_link} target="_blank" href="https://www.itsumma.ru/development" rel="noreferrer">
          <Paragraph weight="bold" className={c.developed_in_label}>Разработано в</Paragraph>
          <ItSummaLogoIcon />
        </a>
      </div>
    </div>
  );
}

FooterBottom.displayName = 'FooterBottom';
export default FooterBottom;
