import { Post } from '~/resources/models';
import config from '~/config';
import { getBreadcrumbs } from './breadcrumbs';
import { itemListElement } from './nav';
import {
  PageType, TArticle, TBodyLd, TBreadcrumbs, TGraph,
  TPostList, TPotentialAction, TProductLD, TWebPage,
} from './types';

const getProduct = (pageType: PageType): TProductLD => {
  let name = 'Годовая подписка Reminder';
  if (pageType === PageType.GIFT) {
    name = 'Годовая подписка Reminder в подарок';
  }
  const result: TProductLD = {
    // '@context': 'http://schema.org',
    '@type': 'Product',
    name,
    description: 'Доступ к лучшим материалам о здоровье, саморазвитии и философии',
    image: 'https://reminder.media/public/subscription/subscription-og.png',
    brand: {
      '@type': 'Brand',
      name: 'Reminder',
    },
    offers:
    {
      '@type': 'Offer',
      priceCurrency: 'RUB',
      price: '8900.00',
      availability: 'http://schema.org/InStock',
      url: 'https://reminder.media/subscription/',
    },
  };
  return result;
};

const getPostList = (posts?: Post[]): TPostList[] | undefined => {
  if (!posts || posts.length === 0) { return undefined; }
  const mainEntity = posts.map((post) => {
    const {
      title, description, image_preview, authors, slug,
    } = post;
    return {
      '@type': 'Article',
      headline: title,
      description,
      image: image_preview?.src || '',
      url: `${config('APP_HOST')}/post/${slug}`,
      author: authors.map((author) => {
        const { id, name } = author;
        return {
          '@type': 'Person',
          name,
          url: `${config('APP_HOST')}/author/${id}`,
        };
      }),
    };
  });
  return mainEntity;
};

export const getJsonLdBody = (
  url: string, name: string, breadcrumbs?: TBreadcrumbs, pageType?: PageType,
  posts?: Post[],
) => {
  const siteName = 'Reminder';

  const pageName = name ? `${siteName} — ${name}` : siteName;
  let body: TBodyLd = { '@context': 'http://schema.org' };

  const alternateName = 'Reminder — издание о здоровье, саморазвитии и практической философии. Мы ищем ответы на самые важные вопросы: как принимать более качественные решения на работе и в личной жизни, как добиваться больших результатов, жить дольше и счастливее.';
  const breadcrumb = getBreadcrumbs(breadcrumbs);
  const mainEntity = getPostList(posts);
  const graph: TGraph = [
    {
      '@type': 'WebPage', url, name: pageName, alternateName, breadcrumb, mainEntity,
    },
    {
      '@type': 'Organization',
      url,
      name: siteName,
      logo: 'https://reminder.media/public/logo.png',
      // sameAs: [
      //   'https://facebook.com/reminder',
      //   'https://twitter.com/reminder',
      // ],
      contactPoint: {
        '@type': 'ContactPoint',
        email: 'newsletter@reminder.media',
        contactType: 'customer service',
      },
    },
    { '@type': 'ItemList', itemListElement }, // navigation
  ];
  if (pageType === PageType.MAIN) {
    const potentialAction: TPotentialAction = {
      '@type': 'SearchAction',
      target: 'https://reminder.media/search?q={search_term_string}',
      'query-input': 'required name=search_term_string',
    };
    graph.push({
      '@type': 'WebSite', url, name: siteName, alternateName, potentialAction,
    });
  }
  if (pageType === PageType.SUBSCRIPTION) {
    graph.push(getProduct(pageType));
  }
  if (pageType === PageType.GIFT) {
    graph.push(getProduct(pageType));
  }

  body = {
    ...body,
    '@graph': graph,
  };
  return body;
};

export const getPostJsonLdBody = (
  url: string, post: Post, breadcrumbs?: TBreadcrumbs,
) => {
  const siteName = 'Reminder';
  const logoSrc = `${config('APP_HOST')}/public/logo-white.png`;

  const {
    image, published_at, updated_at, title, description, id, authors, topic,
  } = post;

  let article: TArticle = {
    '@type': 'Article',
    url,
    headline: title,
    name: title,
    identifier: String(id),
    creator: siteName,
    publisher: {
      '@type': 'Organization',
      name: siteName,
      url: config('APP_HOST'),
      logo: {
        type: 'ImageObject',
        url: logoSrc,
      },
    },
    mainEntityOfPage: url,
    dateModified: new Date(+updated_at * 1000),
  };

  if (image?.src) article = { ...article, image: [image.src] };
  if (published_at) article = { ...article, datePublished: new Date(+published_at * 1000) };
  if (description) article = { ...article, description };
  if (authors?.length > 0) {
    article = {
      ...article,
      keywords: authors.map(({ name }) => `${name}`),
      author: authors.map(({ name }) => ({ '@type': 'Person', name })),
    };
  }
  if (topic?.slug) {
    const topicKey = `Topic:${topic?.slug}`;

    if (article?.keywords) {
      article = { ...article, keywords: [...article.keywords, topicKey] };
    } else {
      article = { ...article, keywords: [topicKey] };
    }
  }
  const breadcrumb = getBreadcrumbs(breadcrumbs);
  const body: TWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url,
    name: title,
    description,
    breadcrumb,
    mainEntity: article,
  };

  return body;
};
