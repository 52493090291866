import React, { FC, ReactElement, useEffect } from 'react';
import { Redirect } from 'react-router';
import usePosts from '~/resources/usePosts';
import { Loader } from '~/components';
import { ResourceMeta, QueryParams } from '~/resources';
import useTag from '~/resources/useTag';
import Helmeter from '~/screens/Helmeter';
import { Tag } from '~/resources/models';
import PreviewPost from './PreviewPost';
import c from './PostList.sass';
import ItemList from '../ItemList/ItemList';
import { isResourceFetchError } from '../Subscription/utils';
import PreviewLongreadPost from './PreviewLongreadPost';

interface TProps {
  slug: string;
  page: number;
  onMeta: (meta: ResourceMeta) => void,
  onLoading: (loading: boolean) => void,
  onTagLoaded: (tag: Tag) => void,
}

const TagPostList: FC<TProps> = ({
  page, onMeta, onLoading, slug, onTagLoaded,
}) => {
  const params: QueryParams = { page: String(page) };
  params.tag_id = slug;

  const {
    meta, fetchBy: fetchPostsBy, data: posts, status,
  } = usePosts(params);

  const {
    fetchBy: fetchTagBy, data: tag,
  } = useTag(slug);

  useEffect(() => {
    fetchTagBy();
  }, []);

  useEffect(() => {
    fetchPostsBy();
  }, [page]);

  useEffect(() => {
    if (meta) {
      onMeta(meta);
    }
  }, [meta]);

  useEffect(() => {
    onLoading(!posts);
  }, [posts]);

  useEffect(() => {
    if (!tag) { return; }
    onTagLoaded(tag);
  }, [tag]);

  const renderItems = () => {
    const items: ReactElement[] = [];

    if (page === 1) {
      items.push(<div className={c.tag_name}>{tag?.name || ''}</div>);
    }

    if (!posts) return items;

    posts.forEach((post) => {
      switch (post.preview_mode) {
        case 'preview_longread': {
          items.push(<PreviewLongreadPost post={post} />);
          break;
        }
        default: {
          items.push(<PreviewPost post={post} showRubricLabel={false} />);
          break;
        }
      }
    });

    return items;
  };

  if (!posts) {
    return isResourceFetchError(posts, status) ? <Redirect to="/404" /> : <Loader />;
  }

  return (
    <>
      <Helmeter
        metaTitle={tag?.meta_title ? `${tag.meta_title} — Reminder` : `${tag?.name} — Reminder`}
        metaDescription={tag?.meta_description}
      />
      <ItemList items={renderItems()} />
    </>
  );
};

TagPostList.displayName = 'TagPostList';
export default TagPostList;
