import React, { FC, useState } from 'react';
import { Page, Paginator, usePageContext } from '~/components';
import config from '~/config';
import Helmeter from '~/screens/Helmeter';
import JsonLd from '~/screens/jsonLd';
import Banner from '~/components/Banner';
import PostList from '~/components/post/PostList';
import { PostType } from '~/resources/PostType';
import GuidesHeader from '~/components/guides/header/GuidesHeader';
import SubscriptionFormSection from '~/components/guides/subscriptionFormSection/SubscriptionFormSection';
import SubscriptionReviews from '~/components/PaidSubscription/ui/SubscriptionReviews';
import { useLocation } from 'react-router';
import { TBreadcrumbs } from '~/utils/jsonLd/types';
import { BREADCRUMB_MAIN } from '~/utils/jsonLd/breadcrumbs';
import usePosts from '~/resources/usePosts';
import classes from './GuidesScreen.sass';

const SUBSCRIPTION_FORM_IDX = 3;

const GuidesScreen: FC = () => {
  const canonicalLink = `${config('APP_HOST')}/guides`;
  const location = useLocation();
  const paramsInit = { type: [PostType.GUIDE], page: String(1) };
  const { data: posts } = usePosts(paramsInit, {}, undefined);
  const breadcrumbs: TBreadcrumbs = [
    BREADCRUMB_MAIN,
    {
      name: 'Гайды',
      href: location.pathname,
    },
  ];

  const { profile } = usePageContext();
  const isProfileSubscribed = profile && profile[0] && profile[0].is_subscribed;

  const [reviewsVisible, setReviewsVisible] = useState(false);

  const handlePaginatorLoading = (loading: boolean, page: number) => {
    if (page === 1) setReviewsVisible(!loading);
  };

  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          title="Гайды — Reminder"
          metaDescription="Гайды Reminder"
          link={[{ rel: 'canonical', href: canonicalLink }]}
        />
        <JsonLd name="Гайды" breadcrumbs={breadcrumbs} posts={posts} />
        <Paginator onLoading={handlePaginatorLoading}>
          {(page, handleMeta, handleLoading) => (
            <PostList
              isMainPage={false}
              page={page}
              onMeta={handleMeta}
              onLoading={handleLoading}
              params={{ type: PostType.GUIDE, page: String(page) }}
              awaitProfile
              beforeElement={<GuidesHeader />}
              getMiddleElements={(isSubscribed) => (
                !isSubscribed
                  ? { [SUBSCRIPTION_FORM_IDX]: <SubscriptionFormSection /> }
                  : undefined
              )}
              noDividersIdx={(isSubscribed) => (
                !isSubscribed
                  ? [SUBSCRIPTION_FORM_IDX - 1, SUBSCRIPTION_FORM_IDX]
                  : undefined
              )}
            />
          )}
        </Paginator>
        {!isProfileSubscribed && reviewsVisible && (
          <SubscriptionReviews withLoader={false} className={classes.reviews_section} />
        )}
      </Page>
    </>
  );
};

GuidesScreen.displayName = 'GuidesScreen';
export default GuidesScreen;
