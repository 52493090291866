import React, { FC, useEffect } from 'react';
import { GiftPage, Page } from '~/components';
import config from '~/config';
import Banner from '~/components/Banner';
import { useLocation } from 'react-router';
import { PageType, TBreadcrumbs } from '~/utils/jsonLd/types';
import { BREADCRUMB_MAIN } from '~/utils/jsonLd/breadcrumbs';
import Helmeter from './Helmeter';
import JsonLd from './jsonLd';

const ogImage = `${config('APP_HOST')}/public/subscription/ogGift.jpg`;

const GiftScreen: FC = () => {
  const location = useLocation();
  const breadcrumbs: TBreadcrumbs = [
    BREADCRUMB_MAIN,
    {
      name: 'Подарочная подписка',
      href: location.pathname,
    },
  ];
  useEffect(() => {
    window.scroll({ top: 0 });
  });

  return (
    <>
      <Banner />
      <JsonLd name="Подарочная подписка" breadcrumbs={breadcrumbs} pageType={PageType.GIFT} />
      <Page>
        <Helmeter
          title="Подарочная подписка — Reminder"
          metaTitle="Подарочная подписка — Reminder"
          metaDescription="Подарочная подписка на лучшее знание о здоровье и саморазвитии"
          img={ogImage}
          srcScripts={['https://yookassa.ru/checkout-widget/v1/checkout-widget.js']}
        />
        <GiftPage />
      </Page>
    </>
  );
};

GiftScreen.displayName = 'GiftScreen';
export default GiftScreen;
