import React, { FC, useEffect, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router';
import {
  Loader, Page,
  ReportContent, ReportHeader,
} from '~/components';
import FormContext from '~/components/Reports/Form';
import { isResourceFetchError } from '~/components/Subscription/utils';
import useReport from '~/resources/useReport';
import Banner from '~/components/Banner';
import config from '~/config';
import { TBreadcrumbs } from '~/utils/jsonLd/types';
import { BREADCRUMB_MAIN } from '~/utils/jsonLd/breadcrumbs';
import Helmeter from './Helmeter';
import JsonLd from './jsonLd';

const ReportScreen: FC = () => {
  const location = useLocation();
  const canonicalLink = `${config('APP_HOST')}/about`;

  const { slug } = useParams<{ slug: string }>();

  const [screenMd, setScreenMd] = useState(false);
  const { search } = useLocation();

  const key = new URLSearchParams(search).get('key');
  const { status, fetchBy, data: report } = useReport(slug, key);
  const breadcrumbs: TBreadcrumbs = [
    BREADCRUMB_MAIN,
    {
      name: 'Отчеты',
      href: '/reports',
    },
    {
      name: `Отчет - ${report?.title}`,
      href: location.pathname,
    },
  ];
  useEffect(() => {
    if (slug) {
      fetchBy();
    }
  }, [slug]);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const onResize = () => setScreenMd(window.innerWidth < 1024);
    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  if (!report) {
    return isResourceFetchError(report, status) ? <Redirect to="/404" /> : <Loader />;
  }

  let gradientStyle = `linear-gradient(${report.gradient.angle}deg`;
  report.gradient.colors.forEach(({ color, offset }, index) => {
    gradientStyle += `, ${color} ${offset * 100}%${index + 1 === report.gradient.colors.length ? ')' : ''}`;
  });

  // TODO create jsonLD
  return (
    <>
      <Banner />
      <Helmeter
        title={report.title}
        description={report.description}
        metaTitle={report.meta_title || report.title}
        metaDescription={report.meta_description || report.description}
        img={report?.cover?.src}
        srcScripts={['https://yookassa.ru/checkout-widget/v1/checkout-widget.js']}
        link={[
          { rel: 'canonical', href: canonicalLink },
        ]}
      />
      <JsonLd name={report.title} breadcrumbs={breadcrumbs} />
      <FormContext>
        {/* {!screenMd && <PageReportHeader report={report} gradient={gradientStyle} />} */}
        <Page hideSeparator>
          {screenMd && <ReportHeader report={report} gradient={gradientStyle} />}
          <ReportContent report={report} gradient={gradientStyle} />
        </Page>
      </FormContext>
    </>
  );
};

ReportScreen.displayName = 'ReportScreen';
export default ReportScreen;
