import config from '~/config';
import {
  TBreadcrumb, TBreadcrumbLD, TBreadcrumbs, TBreadcrumbsLD,
} from './types';

export const BREADCRUMB_MAIN: TBreadcrumb = {
  name: 'Reminder',
  href: '/',
};
export const prepareUrl = (url: string | undefined): string => {
  if (!url) return '';
  return (url?.includes(config('APP_HOST')))
    ? url
    : `${config('APP_HOST')}${url}`;
};

export const getBreadcrumbs = (
  breadcrumbs: TBreadcrumbs | undefined,
): TBreadcrumbsLD | undefined => {
  if (!breadcrumbs) { return undefined; }
  const itemListElement = breadcrumbs.map((item, i) => {
    const href = prepareUrl(item?.href);
    const listItem: TBreadcrumbLD = {
      '@type': 'ListItem',
      position: `0${i + 1}`,
      item: {
        '@id': href,
        name: item.name,
      },
    };
    return listItem;
  });
  return {
    '@type': 'BreadcrumbList',
    itemListElement,
  };
};
