import React, { FC, Fragment, useEffect } from 'react';
import { Redirect } from 'react-router';
import usePosts from '~/resources/usePosts';
import { Loader } from '~/components';
import { ResourceMeta, QueryParams, useConfig } from '~/resources';
import Helmeter from '~/screens/Helmeter';
import { PostType } from '~/resources/PostType';
import PreviewPost from './PreviewPost';
import ItemList from '../ItemList/ItemList';
import { isResourceFetchError } from '../Subscription/utils';

interface TProps {
  slug: string;
  page: number;
  onMeta: (meta: ResourceMeta) => void,
  onLoading: (loading: boolean) => void,
}

const TopicPostList: FC<TProps> = ({
  page, onMeta, onLoading, slug,
}) => {
  const params: QueryParams = { type: [PostType.ARTICLE, PostType.GUIDE], page: String(page) };
  params.topic_id = slug;
  const { data: config } = useConfig();
  const topic = config?.topics.find((item) => item.slug === slug);

  const {
    meta, fetchBy: fetchPostsBy, data: posts, status,
  } = usePosts(params);

  useEffect(() => {
    fetchPostsBy();
  }, [page, slug]);

  useEffect(() => {
    if (meta) {
      onMeta(meta);
    }
  }, [meta]);

  useEffect(() => {
    onLoading(!posts);
  }, [posts]);

  const getItems = () => {
    const items: JSX.Element[] = [];

    if (!posts) return items;

    posts.forEach((post) => {
      items.push(<PreviewPost post={post} />);
    });

    return items;
  };

  if (!posts) {
    return isResourceFetchError(posts, status) ? <Redirect to="/404" /> : <Loader />;
  }

  return (
    <>
      <Helmeter
        metaTitle={`${topic?.title} — Reminder`}
      />
      <ItemList items={getItems()} />
    </>
  );
};

export default TopicPostList;
