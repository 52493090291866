import React, { FC, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Page, Paginator } from '~/components';
import RubricPostList from '~/components/post/RubricPostList';
import config from '~/config';
import Helmeter from '~/screens/Helmeter';
import JsonLd from '~/screens/jsonLd';
import Banner from '~/components/Banner';
import { Rubric } from '~/resources/models';
import useRubric from '~/resources/useRubric';
import { TBreadcrumbs } from '~/utils/jsonLd/types';
import { BREADCRUMB_MAIN } from '~/utils/jsonLd/breadcrumbs';
import { QueryParams } from '~/resources';
import { PostType } from '~/resources/PostType';
import usePosts from '~/resources/usePosts';

type Params = {
    rubric: string
}

const RubricScreen: FC = () => {
  const { rubric } = useParams<Params>();
  const location = useLocation();
  const canonicalLink = `${config('APP_HOST')}/${`rubric/${rubric}`}`;
  const { data: rubricRes } = useRubric(rubric);
  const params: QueryParams = { type: [PostType.ARTICLE, PostType.GUIDE], page: String(1) };
  params.rubric_id = rubric;
  const { data: posts } = usePosts(params);
  const rubricTitle = rubricRes?.meta_title || rubricRes?.name || rubric;

  const [breadcrumbs, setBreadcrumbs] = useState<TBreadcrumbs>([
    BREADCRUMB_MAIN,
    {
      name: `Рубрика — ${rubricTitle}`,
      href: location.pathname,
    },
  ]);

  const handleRubricLoaded = (rubricData: Rubric) => {
    const rubricName = rubricData?.meta_title || rubricData?.name || rubric;
    setBreadcrumbs([
      BREADCRUMB_MAIN,
      {
        name: `Рубрика — ${rubricName}`,
        href: location.pathname,
      },
    ]);
  };

  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          title={`Рубрика ${rubric} — Reminder`}
          metaDescription={`Рубрика ${rubric} Reminder`}
          link={[{ rel: 'canonical', href: canonicalLink }]}
        />
        <JsonLd name={rubric} breadcrumbs={breadcrumbs} posts={posts} />
        <Paginator>
          {(page, handleMeta, handleLoading) => (
            <RubricPostList
              slug={rubric}
              page={page}
              onMeta={handleMeta}
              onLoading={handleLoading}
              onRubrucLoaded={handleRubricLoaded}
            />
          )}
        </Paginator>
      </Page>
    </>
  );
};

RubricScreen.displayName = 'RubricScreen';
export default RubricScreen;
