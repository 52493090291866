export enum PageType {
    NONE = 'none',
    MAIN = 'main',
    SUBSCRIPTION = 'subscription',
    GIFT = 'gift',
}

export type TBreadcrumb = {
    name: string,
    href: string,
}

export type TBreadcrumbs = TBreadcrumb[];

export type TBreadcrumbLD = {
    '@type': string,
    position: string,
    item: {
        '@id': string,
        name: string,
    }
}

export type TBreadcrumbsLD = {
    '@type': string,
    itemListElement: TBreadcrumbLD[],
}

export type TOrganization = {
    '@type': string,
    url: string,
    name: string,
    logo: 'https://reminder.media/public/logo.png',
    sameAs?: string[],
    contactPoint: {
      '@type': string,
      email: string,
      contactType: string,
    },
}

export type TItemListElement = {
    '@type': string,
    position: string,
    name: string,
    description: string,
    url: string,
}

export type TNavItemList = {
    '@type': string,
    itemListElement: TItemListElement[],
}

export type TPotentialAction = {
    '@type': string,
    target: string,
    'query-input': string,
}

export type TWebSite = {
    '@type': string,
    url: string,
    name: string,
    alternateName: string,
    potentialAction: TPotentialAction,
}

export type TWebPage = {
    '@context'?: string,
    '@type': string,
    url: string,
    name: string,
    alternateName?: string,
    description?: string,
    breadcrumb?: TBreadcrumbsLD,
    mainEntity?: TArticle | TPostList[] | string,
}

export type TAboutPage = {
    '@context'?: string,
    '@type': string,
    url: string,
    name: string,
    alternateName?: string,
    description?: string,
    breadcrumb?: TBreadcrumbsLD,
    mainEntity?: TArticle | TPostList[] | string,
}

export type TGraph = (TOrganization | TNavItemList | TWebSite | TWebPage | TProductLD)[]

export type TBodyLd = {
    '@context': string,
    '@graph'?: TGraph,
}

export type TAuthor ={
    '@type': string,
    name: string,
    url?: string,
}

export type TArticle = {
    '@type': string,
    url: string,
    headline: string,
    name: string,
    identifier: string,
    creator: string,
    publisher: {
      '@type': string,
      name: string,
      url: string,
      logo: {
        type: string,
        url: string,
      },
    },
    mainEntityOfPage: string,
    dateModified: Date,
    image?: string[],
    datePublished?: Date,
    description?: string,
    keywords?: string[],
    author?: TAuthor[],
}

export type TPostList ={
  '@type': string,
  headline: string,
  description: string,
  image: string,
  url: string,
  author: TAuthor[],
}

export type TProduct = {
    name: string,
    price: number,
}

export type TProductLD = {
    '@context'?: string,
    '@type': string,
    name: string,
    description: string,
    image: string,
    brand: {
    '@type': string,
    name: string,
    },
    offers: {
    '@type': string,
    priceCurrency: string,
    price: string | number,
    availability: string,
    url: string,
    },
}
