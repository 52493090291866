import React, { FC } from 'react';
import c from '~/components/post/ContentPost.sass';
import ServiceText from '~/components/ServiceText';
import { Page } from '~/components';
import config from '~/config';
import Banner from '~/components/Banner';
import { useLocation } from 'react-router';
import { BREADCRUMB_MAIN } from '~/utils/jsonLd/breadcrumbs';
import { TBreadcrumbs } from '~/utils/jsonLd/types';
import Helmeter from './Helmeter';
import JsonLd from './jsonLd';

const canonicalLink = `${config('APP_HOST')}/about`;

const AboutScreen: FC = () => {
  const location = useLocation();
  const breadcrumbs: TBreadcrumbs = [
    BREADCRUMB_MAIN,
    {
      name: 'О проекте',
      href: location.pathname,
    },
  ];
  return (
    <>
      <Banner />
      <JsonLd name="о проекте" breadcrumbs={breadcrumbs} />
      <Page>
        <Helmeter
          title="О проекте — Reminder"
          metaTitle="О проекте — Reminder"
          metaDescription="О проекте Reminder"
          link={[
            { rel: 'canonical', href: canonicalLink },
          ]}
        />
        <div className={c.container}>
          <div className={c.wrap}>
            <ServiceText name="about_project" />
          </div>
        </div>
      </Page>
    </>
  );
};

AboutScreen.displayName = 'AboutScreen';
export default AboutScreen;
