import React, { FC, useEffect } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import Banner from '~/components/Banner';
import useAuthor from '~/resources/useAuthor';
import config from '~/config';
import { Loader, Page, Paginator } from '~/components';
import AuthorPage from '~/components/page/AuthorPage';
import { isResourceFetchError } from '~/components/Subscription/utils';
import AuthorPostList from '~/components/post/AuthorPostList';
import { TBreadcrumbs } from '~/utils/jsonLd/types';
import { BREADCRUMB_MAIN } from '~/utils/jsonLd/breadcrumbs';
import JsonLd from './jsonLd';
import Helmeter from './Helmeter';

const AuthorScreen: FC = () => {
  const { authorId } = useParams<{ authorId: string }>();
  const { data: author, fetchBy, status } = useAuthor(authorId);
  const location = useLocation();
  const canonicalLink = `${config('APP_HOST')}/author/${authorId}`;
  const breadcrumbs: TBreadcrumbs = [
    BREADCRUMB_MAIN,
    {
      name: `Автор — ${author?.name}`,
      href: location.pathname,
    },
  ];
  useEffect(() => {
    fetchBy();
  }, []);

  if (!author) {
    return isResourceFetchError(author, status) ? <Redirect to="/404" /> : <Loader />;
  }

  return (
    <>
      <Banner />
      <Helmeter
        title="Автор"
        description={`Информация об авторе ${author?.name}`}
        metaTitle={`${author?.name} — Reminder`}
        metaDescription={`Информация об авторе ${author?.name}`}
    //   img={post?.image?.src}
        type="author"
        link={[
          { rel: 'canonical', href: canonicalLink },
        ]}
        srcScripts={['https://yookassa.ru/checkout-widget/v1/checkout-widget.js']}
      />
      <JsonLd name={author?.name || ''} breadcrumbs={breadcrumbs} />
      <Page hideSeparator>
        {author ? <AuthorPage author={author} /> : <Loader />}
        <Paginator>
          {(page, handleMeta, handleLoading) => (
            <AuthorPostList
              authorId={authorId}
              page={page}
              onMeta={handleMeta}
              onLoading={handleLoading}
            />
          )}
        </Paginator>
      </Page>
    </>
  );
};

AuthorScreen.displayName = 'AuthorScreen';
export default AuthorScreen;
