import React, { FC } from 'react';
import { useLocation, useParams } from 'react-router';
import { Page, Paginator } from '~/components';
import config from '~/config';
import TopicPostList from '~/components/post/TopicPostList';
import Banner from '~/components/Banner';
import { QueryParams, useConfig } from '~/resources';
import { TBreadcrumbs } from '~/utils/jsonLd/types';
import { BREADCRUMB_MAIN } from '~/utils/jsonLd/breadcrumbs';
import usePosts from '~/resources/usePosts';
import { PostType } from '~/resources/PostType';
import JsonLd from './jsonLd';
import Helmeter from './Helmeter';

type Params = {
  topic: string;
};

const TopicScreen: FC = () => {
  const { topic } = useParams<Params>();
  const location = useLocation();
  const canonicalLink = `${config('APP_HOST')}/${`topic/${topic}`}`;
  const params: QueryParams = { type: [PostType.ARTICLE, PostType.GUIDE], page: String(1) };
  params.topic_id = topic;
  const { data: posts } = usePosts(params);

  const { data: configData } = useConfig();
  const topicName = configData?.topics.find((item) => item.slug === topic);
  const breadcrumbs: TBreadcrumbs = [
    BREADCRUMB_MAIN,
    {
      name: `Топик — ${topicName?.title}`,
      href: location.pathname,
    },
  ];

  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          title={`Топик ${topicName?.title} — Reminder`}
          metaDescription={`Топик ${topicName?.title} Reminder`}
          link={[
            { rel: 'canonical', href: canonicalLink },
          ]}
        />
        <JsonLd name={topicName?.title} breadcrumbs={breadcrumbs} posts={posts} />
        <Paginator>
          {(page, handleMeta, handleLoading) => (
            <TopicPostList slug={topic} page={page} onMeta={handleMeta} onLoading={handleLoading} />
          )}
        </Paginator>
      </Page>
    </>
  );
};

TopicScreen.displayName = 'TopicScreen';
export default TopicScreen;
