import React, { FC, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Helmeter from '~/screens/Helmeter';
import config from '~/config';
import useSubscriptionList from '~/resources/useSubscriptionList';
import useProfile from '~/resources/useProfile';
import logo from '~/images/logo.svg';
import c from '~/components/Donate/Donate.sass';
import cf from '~/components/Donate/DonateForm.sass';
import anyFetch from '~/resources/anyFetch';
import DonateUnsubscribeModal from '~/components/Donate/DonateUnsubscribeModal';
import {
  AlertModalType, AuthModalFrom, AuthModalState, usePageContext,
} from '~/components/page/context.page';
import { Subscription } from '~/resources/models';
import socAuthLogout from '~/resources/socAuthLogout';
import { useConfig } from '~/resources';
import UnsubscribeConfirmModal from '~/components/Subscription/ModalSubscription/UnsubscribeConfirmModal';
import PromocodeModal from '~/components/Subscription/ModalSubscription/PromocodeModal';
import { Loader } from '~/components';
import ConfirmModal from '~/components/Subscription/ModalSubscription/ConfirmModal';
import { SubscriptionFormToAnnual } from '~/components/PaidSubscription/SubscriptionFormToAnnual';
import MiniBooksList from '~/components/MiniBooksList/MiniBooksList';
import disableScroll from '~/utils/disableScroll';
import AlertModal from '~/components/Alert/AlertModal';
import numberWithSpaces from '~/utils/price';

const keys = {
  LOADING: 0,
  LETTER_SENT: 1,
  SUCCESS: 2,
  NOT_FOUND: 3,
  ALREADY_UNSUBSCRIBED: 4,
  SOMETHING_WENT_WRONG: 5,
  FORM: 6,
  ALREADY_SUBSCRIBED: 7,
  EXPIRED: 8,
  ALREADY_USED: 9,
};

const states = {
  [keys.LOADING]: { key: keys.LOADING },
  [keys.LETTER_SENT]: { key: keys.LETTER_SENT },
  [keys.SUCCESS]: { key: keys.SUCCESS },
  [keys.NOT_FOUND]: { key: keys.NOT_FOUND },
  [keys.ALREADY_UNSUBSCRIBED]: { key: keys.ALREADY_UNSUBSCRIBED },
  [keys.SOMETHING_WENT_WRONG]: { key: keys.SOMETHING_WENT_WRONG },
  [keys.FORM]: { key: keys.FORM },
  [keys.ALREADY_SUBSCRIBED]: { key: keys.ALREADY_SUBSCRIBED },
  [keys.EXPIRED]: { key: keys.EXPIRED },
  [keys.ALREADY_USED]: { key: keys.ALREADY_USED },
};

const REPORTS_URL = `${config('API_HOST')}/report`;
const canonicalLink = `${config('APP_HOST')}/subscription`;

const ProfileScreen: FC = () => {
  const { fetchBy, data } = useConfig();
  const history = useHistory();

  const reportLinks = data ? data.report_links : null;

  const [state, setState] = useState(states[keys.LOADING]);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openPromocodeModal, setOpenPromocodeModal] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(0);
  const [promocodeState, setPromocodeState] = useState(states[keys.FORM]);
  const [modalText, setModalText] = useState('');
  const [openSubModal, setOpenSubModal] = useState(false);

  const { data: subscription, fetchBy: fetchSubscription } = useSubscriptionList();
  const {
    profile: profileContext, auth, alertModal, authModal,
  } = usePageContext();
  const { data: profile, fetchBy: fetchProfile, status } = useProfile();

  useEffect(() => {
    fetchProfile();
    fetchSubscription();
    fetchBy();

    if (typeof window === 'undefined') return;

    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get('subscription_order_id');

    if (orderId) {
      setTimeout(() => {
        fetch(`${config('API_HOST')}/subscriptions/${orderId}/status`)
          .then((response) => response.json())
          .then((response) => {
            let statusResponse = 'error';
            if (response.error) {
              console.log(response.error);
            } else {
              statusResponse = response.data.status;
            }

            switch (statusResponse) {
              case 'pending': {
                setModalText('Еще немного... Платеж обрабатывается и скоро у вас появится подписка');
                break;
              }
              case 'failed': {
                setModalText('Ошибка. Не удалось зачислить платёж');
                break;
              }
              case 'processed': {
                setModalText('Спасибо! Вы успешно сменили подписку на годовую. Проверьте почту');
                break;
              }
              default: {
                setModalText('Упс... Что-то пошло не так');
                break;
              }
            }
            setOpenSubModal(true);
          });
      }, 5000);
    }
  }, []);

  const onError = () => {
    history.push('/subscription');
  };

  if (!profile && status === 2) {
    // history.replace('/auth');
    // return null;
    if (authModal) {
      authModal[1]({ authModalState: AuthModalState.CHECK, authModalFrom: AuthModalFrom.NONE });
    }
    disableScroll(true);
    return <Redirect to="/" />;
  }

  if (!profile) {
    return <Loader />;
  }

  const showConfirmModal = (id: number) => {
    setOpenConfirmModal(true);
    setSubscriptionId(id);
  };

  const promocodeSubmit = async (code: string) => {
    setPromocodeState(states[keys.LOADING]);
    const credentials = auth?.AUTH as RequestCredentials;
    const res = await anyFetch(`${config('API_HOST')}/subscriptions/promocode`, {
      method: 'PUT',
      body: JSON.stringify({ code }),
      credentials,
    });
    if (!res.ok) {
      setPromocodeState(states[keys.SOMETHING_WENT_WRONG]);
    }
    const json = await res.json();
    if (json.error) {
      setPromocodeState(states[keys.SOMETHING_WENT_WRONG]);
    }
    switch (json.status) {
      case 'success': {
        setPromocodeState(states[keys.SUCCESS]);
        break;
      }
      case 'not_found': {
        setPromocodeState(states[keys.NOT_FOUND]);
        break;
      }
      case 'already_subscribed': {
        setPromocodeState(states[keys.ALREADY_SUBSCRIBED]);
        break;
      }
      case 'expired': {
        setPromocodeState(states[keys.EXPIRED]);
        break;
      }
      case 'already_used': {
        setPromocodeState(states[keys.ALREADY_USED]);
        break;
      }
      default: {
        setPromocodeState(states[keys.SOMETHING_WENT_WRONG]);
        break;
      }
    }
  };

  const onSubmit = async () => {
    setOpenModal(true);
    setState(states[keys.LOADING]);

    const credentials = auth?.AUTH as RequestCredentials;
    const res = await anyFetch(
      `${config('API_HOST')}/subscriptions/unsubscribe/${subscriptionId}`,
      { credentials },
    );

    if (!res.ok) {
      setState(states[keys.SOMETHING_WENT_WRONG]);
    }

    const json = await res.json();

    if (json.error) {
      setState(states[keys.SOMETHING_WENT_WRONG]);
    }

    switch (json.data.status) {
      case 'ok': {
        setState(states[keys.SUCCESS]);
        break;
      }
      case 'already_unsubscribed': {
        setState(states[keys.ALREADY_UNSUBSCRIBED]);
        break;
      }
      default: {
        setState(states[keys.SOMETHING_WENT_WRONG]);
        break;
      }
    }
  };

  const onLogout = async () => {
    if (profileContext && profileContext[1]) {
      profileContext[1](undefined);
    }
    if (auth) {
      await socAuthLogout(auth);
      auth.resetAuth();
      history.replace('/');
    }
  };

  const onChangePassword = async () => {
    setOpenModal(true);
    setState(states[keys.LOADING]);
    const token = localStorage.getItem('access_token');
    const res = await anyFetch(`${config('API_HOST')}/auth/change-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const dataResponse = await res.json();
    if (dataResponse.result !== 'ok') {
      setState(states[keys.SOMETHING_WENT_WRONG]);
      return;
    }
    setOpenModal(false);
    if (alertModal) {
      disableScroll(true);
      alertModal[1]({ alertType: AlertModalType.PASSWORD_CHANGE_EMAIL, email: profile?.email });
    }
  };

  const renderActiveSubscription = (sub: Subscription) => (
    <form
      className={cf.form}
      style={{ gap: 0 }}
      key={sub.id}
    >
      <input
        type="hidden"
        id={`subscriptionId-${sub.id}`}
        name="subscriptionId"
        value={sub.id}
      />
      <div className={cf.boxSubscription}>
        <p>
          <span>Подписка: </span>
          {`${sub.description} от ${new Date(+sub.subscribed_at * 1000).toLocaleDateString('ru')}`}
        </p>
        <p>
          <span>Статус: </span>
          Активная
        </p>
        {(sub.status === 'active' && !sub.is_promocode && !sub.revoked_at) ? (
          <>
            <p>
              <span>Дата следующего списания: </span>
              {`${new Date(sub.next_prolongation_at).toLocaleDateString('ru')}`}
            </p>
            <p>
              <span>Сумма следующего списания: </span>
              {`${numberWithSpaces(+sub.amount / 100)}р.`}
            </p>
          </>
        ) : (
          <p>
            <span>Дата окончания: </span>
            {`${new Date(sub.next_prolongation_at).toLocaleDateString('ru')}`}
          </p>
        )}
        {/* {(sub.status === 'active' && sub.title === 'Monthly') && (
          <div className={`${cf.toAnnual}`}>
            <PaidSubscriptionPubForm toAnnual={sub.id} />
          </div>
        )} */}
        {(sub.status === 'active' && sub.revoked_at) && (
          <div className={c.toSubs_wrapper}>
            <Link to="/subscription" className={c.toSubs_button}>
              Включить автопродление
            </Link>
          </div>
        )}
        {(sub.status === 'active' && sub.title === 'Monthly') && (
          <SubscriptionFormToAnnual />
        )}
      </div>
      {!sub.revoked_at && (
        <button
          type="button"
          onClick={() => showConfirmModal(sub.id)}
          aria-label="Отписаться"
          className={`${cf.inputText} ${cf.submit_revoked}`}
          style={{ transform: 'translateY(45px)' }}
        >
          <span className={cf.submit_title}>Отписаться</span>
          <span className={cf.submit_subtitle}>Подписка сохранится на оплаченный период</span>
        </button>
      )}
    </form>
  );

  const renderInactiveSubscription = (sub: Subscription) => (
    <div
      className={cf.form}
      style={{ gap: 0 }}
      key={sub.id}
    >
      <div className={cf.boxSubscription}>
        <p>
          <span>Подписка: </span>
          {`${sub.description} от ${new Date(+sub.subscribed_at * 1000).toLocaleDateString('ru')}`}
        </p>
        <p>
          <span>Статус: </span>
          Неактивная
        </p>
      </div>
    </div>
  );

  const renderSubscribe = () => (
    <form
      className={cf.form}
      onSubmit={onError}
      style={{ gap: 0 }}
      key={0}
    >
      <div className={cf.boxSubscription}>
        У вас нет активных подписок
      </div>
      <button
        type="submit"
        aria-label="Подписаться"
        className={`${cf.inputText} ${cf.submit}`}
        style={{ transform: 'translateY(45px)' }}
      >
        Подписаться
      </button>
    </form>
  );

  const form = [];
  const isNotSubscribed = typeof subscription === 'object' && subscription.filter((sub) => sub.status === 'active').length < 1;
  const isAnnualSubscriber = typeof subscription === 'object' && subscription.filter((sub) => (sub.status === 'active' && sub.title === 'Annual')).length > 0;
  const isPasswordChangable = profile.is_admin || (profile.email && profile.is_active);
  if (isNotSubscribed) {
    form.push(renderSubscribe());
  }
  if (subscription && subscription.length > 0) {
    subscription.forEach((sub) => {
      form.push(sub.status === 'active' ? renderActiveSubscription(sub) : renderInactiveSubscription(sub));
    });
  }

  const downloadReports = async (slug = '', fileName = '6 Reminder Mini Books') => {
    const credentials = auth?.AUTH as RequestCredentials;
    setOpenModal(true);
    setState(states[keys.LOADING]);
    const res = await anyFetch(
      `${REPORTS_URL}?slug=${slug}`,
      {
        method: 'GET',
        credentials,
      },
    );
    if (!res.ok) {
      setState(states[keys.SOMETHING_WENT_WRONG]);
      setOpenModal(true);
      return;
    }

    const fileBlob = await res.blob();
    const url = window.URL.createObjectURL(fileBlob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    setOpenModal(false);
  };
  return (
    <>
      <Helmeter
        title="Поддержите Reminder. Инвестируйте в себя."
        metaTitle="Поддержите Reminder. Инвестируйте в себя."
        metaDescription="Профиль пользователя Reminder"
        link={[
          { rel: 'canonical', href: canonicalLink },
        ]}
        srcScripts={['https://yookassa.ru/checkout-widget/v1/checkout-widget.js']}
      />
      <AlertModal />
      <DonateUnsubscribeModal
        keys={keys}
        stateKey={state.key}
        open={openModal}
        setOpenModal={setOpenModal}
      />
      <UnsubscribeConfirmModal
        onSubmit={onSubmit}
        open={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
      />
      <ConfirmModal
        onSubmit={() => {
          setOpenSubModal(false);
          history.push('/profile');
        }}
        open={openSubModal}
        setOpenModal={setOpenSubModal}
        text={modalText}
        textSubmitButton="Закрыть"
        textCancelButton={undefined}
      />
      <PromocodeModal
        promocodeSubmit={promocodeSubmit}
        open={openPromocodeModal}
        setOpenModal={setOpenPromocodeModal}
        keys={keys}
        stateKey={promocodeState.key}
      />
      <div className={c.container} style={{ height: '100vh', overflowY: 'scroll' }}>
        <div className={c.wrap}>
          <a href="/">
            <img src={logo} className={c.logo} alt="#" />
          </a>
          <div className={c.titleContainer}>
            <div className={c.titleBox}>
              <p className={c.title}>Личный кабинет</p>
              <div className={c.sideBar}>
                <Link to="/">На главную</Link>
                { isNotSubscribed
                  ? (<button onClick={() => setOpenPromocodeModal(true)} type="button">Промокод</button>)
                  : (<a href="https://t.me/+0R5A-T2WzYg4MGZi" target="_blank" rel="noreferrer">Телеграм-канал</a>)}
                {(isAnnualSubscriber || profile.is_admin) && (
                  <a href="#mini-book-container">Мини-книги</a>
                )}
                <button onClick={onLogout} type="button">Выйти</button>
              </div>
            </div>
            <div className={c.titleNotice}>
              Если что-то не работает, напишите нам:
              <a href="mailto:newsletter@reminder.media"> newsletter@reminder.media</a>
            </div>
          </div>
          {(profile?.email) && (
            <>
              <p className={c.subtitle}>
                Ваш email:
                {' '}
                {profile?.email}
              </p>
              {isPasswordChangable && (
                <button
                  className={c.changePassword}
                  onClick={onChangePassword}
                  type="button"
                >
                  Сменить пароль
                </button>
              )}
              {form}
            </>
          )}
          {/* Form */}
          {(isAnnualSubscriber || profile.is_admin) && (
            <div id="mini-book-container">
              <MiniBooksList
                onClick={downloadReports}
                books={reportLinks}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

ProfileScreen.displayName = 'ProfileScreen';
export default ProfileScreen;
