import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

export const RemoveTrailingSlash = () => {
  const location = useLocation();

  // If the last character of the url is '/'
  if (location.pathname.match('/.*/$')) {
    return (
      <Redirect to={{
        pathname: location.pathname.replace(/\/+$/, ''),
        search: location.search,
      }}
      />
    );
  } return null;
};
